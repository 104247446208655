<template>
</template>

<script>
import http from '../mixins/api'
import store from '@/store'
import { v4 } from "uuid"


export default {
    name: 'AdminManage',
    components: {
    },
    data: function() {
        return {
        }
    },
    async beforeRouteEnter(route, redirect, next) {
        let query = route.query
        localStorage.removeItem("state")
        next(vm => vm.redirectLoginPage(query.redirectUri))
    },

    methods: {
        redirectLoginPage(redirectUri) {
            localStorage.removeItem("state")
            const loginState = v4()
            localStorage.setItem("state", loginState)
            const authLoginUrl = new URL(redirectUri);
            authLoginUrl.searchParams.append('redirectUri', this.$constants.local);
            authLoginUrl.searchParams.append('state', loginState);
            authLoginUrl.searchParams.append('clientId','0a637b53-170c-42e5-8b05-c7d294768474');
            location.href = authLoginUrl.toString()
        },

    }
}
</script>

<style scoped>
</style>
